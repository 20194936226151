import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Blog" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Blog</h2>
          <h3>Coming soon!</h3>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.largePic.childImageSharp.fluid}
              className="kg-image"
            />
          </figure>
          <p>
            Over the course of her life, Seraphina has followed her passions and
            curiosities all over the world. This blog includes a series of short
            stories detailing her experiences and adventures!
          </p>
          <p>
            Highlights to look forward to include ancient initiation ceremonies
            in rural Senegal, leading sea kayaking trips to 10,000ft Alaskan
            glaciers, living with Ezra Pound's daughter in an Italian alpine
            castle, and teaching dance to 100 Israeli and Palestinian teenagers.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    largePic: file(relativePath: { eq: "glacier.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
